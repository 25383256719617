<template>
  <div>
    <a-modal
      title="提现明细"
      :visible="dialogVisible"
      width="50%"
       @cancel="handleCancel"
       :footer="null"
    >
      <div class="content">
       <a-table
        :loading="loading"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
      >
       
      </a-table>
      </div>
     <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1
        }"
      >
        <a-button @click="dialogVisible = false">取消</a-button>

        <a-button type="primary" @click="dialogVisible = false" style="margin-left: 10px">提 交</a-button>
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      loading: false,
      list:[
        {
          withdrawalAmount:100,
        }
      ],
      columns: [{
          title: '提现金额',
          dataIndex: 'withdrawalAmount',
          align: 'center'
        },
        {
          title: '待提现金额',
          dataIndex: 'pendingWithdrawalAmount',
          align: 'center'
        },
        {
          title: '提现方式',
          dataIndex: 'withdrawalMethod',
          align: 'center'
        },
        {
          title: '提现时间',
          dataIndex: 'withdrawalTime',
          align: 'center'
        },
        {
          title: '到账时间',
          dataIndex: 'paymentDate',
          align: 'center'
        },
        
      ]
    };
  },
  methods: {
    open() {
      this.dialogVisible = true;
      this.getWithdrawal();
    },
    // 体现明细
    getWithdrawal(){
        console.log("提现明细");
        
    },
    handleCancel(){
        this.dialogVisible = false
    }
  },
};
</script>

<style scoped>

.content {
  /* margin-bottom: 70px; */
  /* display: flex;
    align-items: center;
    justify-content: center; */
  padding-bottom: 50px;
  text-align: left;
}
  
</style>
    