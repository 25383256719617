<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="昵称" prop="userName">
                <a-input v-model="queryParam.userName" @pressEnter="handleQuery" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>

            <a-col :md="8" :sm="24">
              <a-form-item label="ID" prop="id">
                <a-input v-model="queryParam.id" @pressEnter="handleQuery" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>

            <a-col :md="8" :sm="24">
              <a-form-item label="注册时间" prop="createTime">
                <a-date-picker style="width: 100%" v-model="queryParam.createTime"
                               :show-time="{defaultValue: moment('00:00:00', 'HH:mm:ss')}"
                               valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
              </a-form-item>
            </a-col>

             <a-col :md="8" :sm="24">
              <a-form-item label="团队人数" prop="num">
                <a-input v-model="queryParam.num" @pressEnter="handleQuery" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>


            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{$t('通用.按钮.查询')}}</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />{{$t('通用.按钮.重置')}}</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
     
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
      >
        <span slot="operation" slot-scope="text, record">
          <a @click="$refs.saveRef.open(record)">
            查看详情
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :pageSizeOptions="pageSizeOptions"
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => getTotal(total)"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />

       <saveView ref="saveRef"></saveView>

    </a-card>
  </page-header-wrapper>

  
</template>

<script>
import saveView from './save.vue';
import moment from 'moment';
// import { pageSearch, delSearch } from '@/api/user/search'
import {mapGetters} from 'vuex'
// import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'promotionSpecialist',
  components: {
    saveView
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [
        {
          id:1,
          userName:'sdfsdf'
        }
      ],
      pageSizeOptions: ['10', '20', '50', '100'],
      selectedRowKeys: [],
      selectedRows: [],
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userName:null,
        id:null,
        createTime:null,
        num: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '用户昵称',
          dataIndex: 'userName',
          align: 'center'
        },
        {
          title: 'ID',
          dataIndex: 'id',
          align: 'center'
        },
        {
          title: '手机号',
          dataIndex: 'phone',
          align: 'center'
        },
        {
          title: '注册时间',
          dataIndex: 'createTime',
          align: 'center'
        },
        {
          title: '团队人数',
          dataIndex: 'num',
          align: 'center'
        },
        {
          title: '累计佣金',
          dataIndex: 'commission',
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  methods: {
    moment,
    /** 查询用户-用户搜索记录列表 */
    getList () {
      console.log('需要重写接口');
      
      // this.loading = true
    //  pageSearch(this.queryParam).then(response => {
    //     this.list = response.data.records
    //     this.total = response.data.total
    //     this.loading = false
    //   })
    },
    getTotal(total) {
      return this.$t('通用.文本.共')+total+this.$t('通用.文本.条')
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        id: null,
        userName: null,
        createTime: null,
        num: null,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    }
  }
}
</script>
