<template>
  <div>
    <a-drawer
      title="推广专员团队详情"
      placement="right"
  
      :visible="visible"
      width="1200"
      @close="onClose"
    >
     <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="昵称" prop="name">
                <a-input v-model="queryParam.name" @pressEnter="handleQuery" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>

            <a-col :md="8" :sm="24">
              <a-form-item label="ID" prop="id">
                <a-input v-model="queryParam.id" @pressEnter="handleQuery" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>

            <a-col :md="8" :sm="24">
              <a-form-item label="注册时间" prop="createTime">
                <a-date-picker style="width: 100%" v-model="queryParam.createTime"
                               :show-time="{defaultValue: moment('00:00:00', 'HH:mm:ss')}"
                               valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
              </a-form-item>
            </a-col>


            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />搜索</a-button>
                
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <a-table
        :loading="loading"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
      >
        <span slot="operation" slot-scope="text, record">
          <a @click="$refs.detatilsRef.open(record)">
            提现明细
          </a>
        </span>
      </a-table>

      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="visible = false">
          取消
        </a-button>
        <a-button type="primary" @click="visible = false">
          确定
        </a-button>
      </div>

      <detatilsView ref="detatilsRef"></detatilsView>
    </a-drawer>
  </div>
</template>

<script>
import moment from 'moment';
import detatilsView from './details.vue'
export default {
  components:{
        detatilsView
    },
    data() {
    return {

       // 查询参数
      queryParam: {
        name:null,
        id:null,
        createTime:null,
       
      },
      advanced: false,

      loading:false,
      visible:false,
      list:[
        {
          level:1,
        }
      ],
      columns: [{
          title: '层级',
          dataIndex: 'level',
          align: 'center'
        },
        {
          title: '昵称',
          dataIndex: 'name',
          align: 'center'
        },
        {
          title: 'ID',
          dataIndex: 'id',
          align: 'center'
        },
        {
          title: '手机号',
          dataIndex: 'phone',
          align: 'center'
        },
        {
          title: '注册时间',
          dataIndex: 'createTime',
          align: 'center'
        },
        {
          title: '累计单量',
          dataIndex: 'numAll',
          align: 'center'
        },
        {
          title: '累计佣金',
          dataIndex: 'commissionAll',
          align: 'center'
        },

         {
          title: '累计提现',
          dataIndex: 'accumulatedWithdrawal',
          align: 'center'
        },

        {
          title: '待提现',
          dataIndex: 'pendingWithdrawal',
          align: 'center'
        },

        {
          title: '可提现',
          dataIndex: 'withdrawable',
          align: 'center'
        },

        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]

    }
  },
  methods:{
    moment,
    open(row){
      console.log(row);
      
      this.visible = true;
      this.getList()
    },
    // 
    getList(){
      console.log('获取数据');
      
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.getList()
    },
    onClose() {
      this.visible = false;
    }
  }
}
</script>