var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"title":"提现明细","visible":_vm.dialogVisible,"width":"50%","footer":null},on:{"cancel":_vm.handleCancel}},[_c('div',{staticClass:"content"},[_c('a-table',{attrs:{"loading":_vm.loading,"rowKey":"id","columns":_vm.columns,"data-source":_vm.list,"pagination":false}})],1),_c('div',{style:({
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 1
      })},[_c('a-button',{on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("取消")]),_c('a-button',{staticStyle:{"margin-left":"10px"},attrs:{"type":"primary"},on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("提 交")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }